<template>
    <QueryHome v-slot="{ entry }">
        <TemplateDefault v-if="entry">
            <BaseHero
                :hero-link="entry.heroLink"
                :hero-event="entry.heroEvent[0]"
                :image="entry.heroImages[0]"
            />

            <QueryEventBundle
                v-if="userStore.loggedIn"
                :query="{
                    __typename: '[custom]event_bunlde_user_saved',
                    preset: 'user-saved'
                }"
            >
                <template #default="{ events }">
                    <BaseSection width="bleed">
                        <template #title>
                            Mijn lijst
                        </template>

                        <template #default>
                            <EventSlider
                                :events="events"
                                card-size="large"
                            />
                        </template>
                    </BaseSection>
                </template>
            </QueryEventBundle>

            <FlexibleSections
                v-if="entry.flexibleSections"
                :sections="entry.flexibleSections"
            />
        </TemplateDefault>
    </QueryHome>
</template>

<script setup lang="ts">
import { useEventFiltersStore } from '~/store/eventFilters';
import { useUserStore } from '~/store/user';

const eventFiltersStore = useEventFiltersStore();
const userStore = useUserStore();

onBeforeMount(() => {
    eventFiltersStore.filtersVisible = false;
});
</script>

