<template>
    <StateSwitch
        :state="status"
        :status-code="statusCode"
    >
        <slot
            name="default"
            :entry="entry"
        />
    </StateSwitch>
</template>

<script setup lang="ts">
import { useCraftQuery, type CraftResponseObject } from '~/composables/useCraftQuery';
import HomeQuery from '~/graphql/queries/Home.graphql';
import type {
    Home_Home_Entry
} from '~/@types/craft-schema';

const response = await useAsyncQuery({
    query: HomeQuery
});

interface ResponseObject extends CraftResponseObject {
    entry: Ref<Home_Home_Entry>;
}

const {
    status,
    statusCode,
    entry,
    seo
} = await useCraftQuery(response) as ResponseObject;

if (seo.value) {
    useHead(seo.value);
}
</script>
